import {Card, Col, Row, Modal} from "antd";
import {CheckOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import React, {useCallback, useState} from "react";
import PropTypes from 'prop-types';
import Spinner from "../../Spinner";

function showConfirm(type, title, content, callback) {
  Modal[type]({
    title,
    content,
    onOk: () => {
      if (callback)
        callback();
    }
  })
}

const AnswerList = (props) => {
  const [question] = useState(props.question); // default value for question is {}
  const [selectedAnswer, setSelectedAnswer] = useState(''); // default value for question is {}
  if (!question.id) return null;
  const [answers, setAnswers] = useState(question.answers);
  const setSelected = (answer) => {
    setSelectedAnswer(answer);
    const newA = answers.map((a) => {
      if (a.id === answer.id)
        return {...a, selected: true};
      else
        return {...a, selected: false};
    });
    setAnswers(newA);
  };

  const [checkingAnswer, setCheckingAnswer] = useState(false);
  const nextOrPrevious = (move) => {
    //TODO: Before first check valid answer and on ok execute callback
    // but if the answer is not ok
    if (selectedAnswer) {
      const {is_correct} = selectedAnswer;
      if (is_correct){
        const s = showConfirm('success', 'Correcto 😀', props.question.commentary, () => {
          props.onNextOrPrevious(move, selectedAnswer);
        });
      }
      else
        showConfirm('error', 'Incorrecto... 😕️', props.question.commentary);
    } else {
      showConfirm('warning', 'Por favor selecciona al menos una opción')
    }
  };

  const back = (move) => {
    props.onNextOrPrevious(move, selectedAnswer);
  };

  const canMove = useCallback((move) => {
    const {total_questions} = props.chapter;
    if (move > 0) {
      return (question.number + move) <= total_questions + 1
    } else {
      return (question.number + move) >= 1
    }
  }, [question?.id]);

  return <Spinner loading={checkingAnswer}>
    <Row justify={'center'}>
      <Col span={4} className={'arrow-answer left'}>
        { canMove(-1) && <LeftOutlined onClick={() => back(-1)} />}
      </Col>

      <Col span={13}>
        {
          answers.map((a,i) => {
            return <Card onClick={() => setSelected(a)} key={i} className={'card-answer'} hoverable>
              {
                a.selected && <span style={{position: 'absolute', top: '3%', right: '5%', width: '15px', height: '15px'}}>
              <CheckOutlined className={'answer-selected'} />
            </span>
              }
              <p>{a.title}</p>
            </Card>
          })
        }
      </Col>
      <Col span={4} className={'arrow-answer right'}>
        { canMove(1) && <RightOutlined onClick={() => nextOrPrevious(1)} />}
      </Col>
    </Row>
  </Spinner>
};

AnswerList.propTypes = {
  question: PropTypes.object.isRequired,
  chapter: PropTypes.object.isRequired,
  onNextOrPrevious: PropTypes.func,
};

AnswerList.defaultProps = {
  onNextOrPrevious: () => null
};

export default AnswerList;
