import React, {useEffect, useState} from 'react';
import './category-styles.less';
import { Typography, Layout, Row, Col, Button, Avatar } from 'antd';
import PropTypes from 'prop-types';
import Spinner from '../../Spinner';
// import Rails from '@rails/ujs';
import {fetchCategory} from '../../../actions/category.actions';
// console.log(Rails.csrfToken());
const { Header, Content } = Layout;
const { Title, Text } = Typography;

const MainCategoryPage = (props) => {

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  
  useEffect(() => {
    setLoading(true);
    fetchCategory().then((data) => {
      const {main_categories} = data.data;
      setCategories(main_categories)
    }).catch(error => {
      console.error(error);
    }).then(() => {
      setLoading(false);
    })
  }, []);

  const navigateNested = (parentId) => {
    window.location.href = `/book_presentation/list_books?category_id=${parentId}`;
  };

  const goRoot = (parentId) => {
    window.location.href = props.path_root; 
  };

  const goSystem = () => window.history.back();
  return (
      <Layout style={{minHeight: '100vh'}}>
        <Header>
            <Col span={12} style={{display: 'flex', justifyContent: 'flex-start'}}>
              <Title className={'title-header'}>Bienvenido Escuela Bíblica Virtual</Title>
            </Col>  
            <Col span={12} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <Button className={'go-root-button-header'} onClick={goRoot}>
                Inicio
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button className={'go-back-button-header'} onClick={goSystem}>
                Atras
              </Button>
            </Col>
        </Header>
        <Spinner loading={loading} >
          <Content className={'content-categories'}>
            <Row><Col span={24} style={{textAlign: 'center'}}><Title className={'title-category'}>Selecciona una Categoría de Estudio</Title></Col></Row>
            <Row className={'category-list'} gutter={24} justify={'space-between'} align={'center'}>
              {
                categories.map((c, i) => {
                  return <Col key={i} span={8} xs={{span: 24}} sm={{span: 12}} md={{span: 12}}
                              style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
                      <Avatar 
                        onClick={() => navigateNested(c.id)}
                        className={'clickeable'}
                        size={200} src={c.image_url} />
                      <Text className={'category-name'}>{c.name}</Text>
                  </Col>
                })
              }
            </Row>
          </Content>
        </Spinner>
      </Layout>
  );
};

MainCategoryPage.propTypes = {
  categoryId: PropTypes.string,
  path_root: PropTypes.string,
};

export default MainCategoryPage;

