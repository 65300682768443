import {Card, Col, Row, Modal} from "antd";
import {CheckOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {Typography, Layout, Button, Image, Affix, Progress} from 'antd';
import React, {useCallback, useState, useEffect} from "react";
import PropTypes from 'prop-types';
import Spinner from "../../Spinner";

const DecisionList = (props) => {
  //const [decisions, setDecision] = useState(props.decisions);
  const {Title, Text} = Typography;
  const [decisions] = useState(props.decisions); // default value for question is {}
  const [chapter] = useState(props.chapter); // default value for question is {}
  const [loading, setLoading] = useState(false);

  const back = (move) => {
    props.onNextOrPrevious(move, null);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(
      () => { setLoading(false); },
      1000
    )
  }, []);
  
  return <Spinner loading={loading}>
          <Row justify={'center'}>
            <Col span={4} className={'arrow-answer left'}>
            { <LeftOutlined onClick={() => back(0)} />}
            </Col>
            <Col span={12}>
              { 
                decisions && decisions.map(function(decision, i){
                  return  <Card key={i} className={'card-answer'} hoverable>
                            <Col>
                                <Col className={'question-title'}> 
                                  <Text className={'question-title'}>
                                    {decision.title}
                                  </Text>
                                </Col>
                                <Col className={'question-title'}>---</Col>
                                <Col>
                                  <Text className={'title-category'}>
                                    {decision.help_text}
                                  </Text>
                                </Col>
                            </Col>
                          </Card>;
                })
              }
            </Col>
            <Col span={4} className={'arrow-answer right'}>
            </Col>
          </Row>
        </Spinner>
};

DecisionList.propTypes = {
  chapter: PropTypes.object.isRequired,
  decisions: PropTypes.object.isRequired,
  onNextOrPrevious: PropTypes.func,
};

DecisionList.defaultProps = {
  onNextOrPrevious: () => null
};

export default DecisionList;
