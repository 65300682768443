import axios from "axios";

export const fetchBooks = async (categoryId = null) => {
  return await axios.get("/books.json", {
    params: { cateogory_id: categoryId },
  });
};

export const fetchChapters = async (bookId = null) => {
  return await axios.get("/chapters.json", {
    params: { book_id: bookId },
  });
};

export const fetchQuestion = async (chapterId = null, questionId = null) => {
  if (!questionId) questionId = 1;
  return await axios.get(`/questions/${questionId}.json`, {
    params: { question_id: questionId, chapter_id: chapterId },
  });
};
