import './styles.less';
import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../Spinner';
import {Typography, Layout, Row, Col, Button, Image, Affix, Progress, Modal} from 'antd';
import {fetchQuestion} from '../../../actions/book.actions';
import AnswerList from "./AnswerList";
import DecisionList from "./DecisionList";
import {FileDoneOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {orange} from "@ant-design/colors";

const {Header, Content, Footer} = Layout;
const {Title, Text} = Typography;

const { info } = Modal;

function showInfo(title, content) {
  info({
    title: title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    onOk() {
      console.log('OK');
    },
    onCancel() {
      console.log('Cancel');
    },
  });
}

const ToolFloatQuestion = (props) => {
  const {chapter} = props;
  return <div className={'tool-question'} onClick={() => showInfo(chapter.title, chapter.subtitle)}>
    <FileDoneOutlined />
  </div>
};

const Question = (props) => {
  const [question, setQuestion] = useState({});
  const [chapter, setChapter] = useState({});
  const [book, setBook] = useState({});
  const [loading, setLoading] = useState(false);
  const [canShowDecision, setCanShowDecision] = useState(false);

  const fetchQuestionByNumber = (questionNumber) => {
    setLoading(true);
    fetchQuestion(props.chapterId, questionNumber).then((data) => {
      const {book} = data.data;
      const {chapter} = data.data;
      const {question} = data.data;
      console.log(data.data);
      setBook(book);
      setChapter(chapter);
      setQuestion(question);
    }).catch(error => {
      console.error(error);
    }).then(() => {
      setLoading(false);
    })
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const questionId = params.get('question_id');
    fetchQuestionByNumber(questionId);
  }, []);

  const goBack = () => window.history.back();

  const goRoot = (parentId) => {
    window.location.href = props.path_root; 
  };

  const titleHeader = `${chapter?.number} ${chapter?.title}`;

  const progress = useMemo(() => {
    const {total_questions} = chapter;
    return Math.round((question.number / total_questions) * 100)
  }, [question?.id]);

  const nextOrPrevious = (move) => {
    const {number} = question;
    const {total_questions} = chapter;
    if ((number + move) > total_questions) {
      setCanShowDecision(true);
    } else {
      setCanShowDecision(false);
      fetchQuestionByNumber(number + move);
    }
    
  };

  return <Layout style={{minHeight: '100vh'}}>
    <Spinner loading={loading}>
      <Header>
        <Col span={12} style={{display: 'flex', justifyContent: 'flex-start'}}>
          <Title className={'title-header'}>Bienvenido Escuela Bíblica Virtual</Title>
        </Col>  
        <Col span={12} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
          <Button className={'go-back-root-header'} onClick={goRoot}>
            Inicio
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button className={'go-back-button-header'} onClick={goBack}>
            Atras
          </Button>
        </Col>
      </Header>
      <Content>
        <div style={{width: '100%', height: '200px', marginBottom: '15px', position: 'relative'}}>
          <Image src={chapter.image_url} width={'100%'} height={'100%'} style={imgBannerStyle}/>
          <ToolFloatQuestion chapter={chapter}/>
        </div>
        <div className="container-w-margin">
          {
            canShowDecision
            ? <DecisionList onNextOrPrevious={nextOrPrevious} chapter={chapter} decisions={chapter.decisions}/> 
            : 
            <Col>
              <Row justify={'center'}>
                <Col span={15}>
                  <Text className={'question-title'}>
                    {question.number}. {question.title}
                  </Text>
                </Col>
              </Row>
              <Row justify={'center'}>
                <Col span={15}>
                  <Text className={'help-text'} onClick={() => showInfo(question.reference_text, question.help_text)}>
                    {question.reference_text}
                  </Text>
                </Col>
              </Row>
              <Row justify={'center'}>
                <Col span={15}>
                  <Text className={'choose-answer-helper'}>
                    Por favor selecciona al menos una opción
                  </Text>
                </Col>
              </Row>
              <AnswerList onNextOrPrevious={nextOrPrevious} chapter={chapter} question={question}/>
            </Col>
          }
        </div>
      </Content>
      <Affix offsetBottom={0}>
        <Footer>
          <Progress strokeColor={orange[5]} strokeLinecap="square" percent={progress} />
        </Footer>
      </Affix>
    </Spinner>
  </Layout>
};

const imgBannerStyle = {
  objectFit: 'cover',
};

Question.propTypes = {
  chapterId: PropTypes.number.isRequired,
  path_root: PropTypes.string,
  canShowDecision: PropTypes.bool,
};

export default Question;