import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

const Spinner = (props) => {

  const {loading} = props;
  const {children} = props;
  return loading ? <Spin style={styles} size={'large'} /> : children ? children : null;
};

Spinner.propTypes = {
  loading: PropTypes.bool.isRequired,
};

const styles = {
  display: 'flex',
  padding: 10, 
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
};



export default Spinner;
