// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import("../template/js");

// import styles
require("../template/styles/style.scss");
// custom css
require("./../css_pages/users.scss");

// import js
require("@rails/ujs").start();
require("@rails/activestorage").start();

const images = require.context("../images", true);
const imagePath = name => images(name, true);

// Fix Tooltips on back safari
$(document).ready(() => {
  $('[data-toggle="tooltip"]').on("click", function () {
    $(this).tooltip("hide");
  });
});
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
