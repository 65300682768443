import './book-styles.less';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../Spinner';
import { Typography, Layout, Row, Col, Button, Avatar } from 'antd';
import {fetchBooks} from '../../../actions/book.actions';

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const List = (props) => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchBooks(props.categoryId).then((data) => {
      const {books} = data.data;
      setBooks(books);
    }).catch(error => {
      console.error(error);
    }).then(() => {
      setLoading(false);
    })
  }, []);

  const goBack = () => window.history.back();

  const goRoot = (parentId) => {
    window.location.href = props.path_root; 
  };

  const goToChapters = (bookId) => {
    window.location.href = `/book_presentation/list_chapters?book_id=${bookId}`;
  }

  return <Layout style={{minHeight: '100vh'}}>
    <Header>
        <Col span={12} style={{display: 'flex', justifyContent: 'flex-start'}}>
          <Title className={'title-header'}>Bienvenido Escuela Bíblica Virtual</Title>
        </Col>  
        <Col span={12} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
          <Button className={'go-back-root-header'} onClick={goRoot}>
            Inicio
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button className={'go-back-button-header'} onClick={goBack}>
            Atras
          </Button>
        </Col>
    </Header>
    <Spinner loading={loading} >
      <Content>
        <Row><Col span={24} style={{textAlign: 'center'}}><Title className={'title-category'}>Selecciona un Libro de Estudio</Title></Col></Row>
        <Row className={'book-list'} gutter={24} justify={'space-between'} align={'center'}>
          {
                books.map((b, i) => {
                  return <Col key={i} span={8} xs={{span: 24}} sm={{span: 12}} md={{span: 12}}
                              style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
                      <Avatar 
                        onClick={() => goToChapters(b.id)}
                        className={'clickeable'}
                        size={200} src={b.image_url} />
                      <Text className={'book-name'}>{b.title}</Text>
                  </Col>
                })
          }
        </Row>
      </Content>
    </Spinner>
  </Layout>
}

List.propTypes = {
  categoryId: PropTypes.number.isRequired,
  path_root: PropTypes.string,
};

export default List;