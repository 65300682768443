import './book-styles.less';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../Spinner';
import { Typography, Layout, Row, Col, Button, Avatar, Divider, Empty, Tooltip } from 'antd';
import {fetchChapters} from '../../../actions/book.actions';
import {
  InfoCircleOutlined
} from '@ant-design/icons';

const { Header, Content } = Layout;
const { Title } = Typography;

const Chapters = (props) => {
  const [chapters, setChapters] = useState([]);
  const [book, setBook] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchChapters(props.bookId).then((data) => {
      const {book} = data.data;
      const {chapters} = data.data;
      setBook(book);
      setChapters(chapters);
    }).catch(error => {
      console.error(error);
    }).then(() => {
      setLoading(false);
    })
  }, []);

  const goBack = () => window.history.back();

  const goRoot = (parentId) => {
    window.location.href = props.path_root; 
  };

  const goToQuestion = (chapterId) => {
    window.location.href = `/book_presentation/fetch_question?chapter_id=${chapterId}`;
  }

  return <Layout style={{minHeight: '100vh'}}>
    <Header>
      <Col span={12} style={{display: 'flex', justifyContent: 'flex-start'}}>
        <Title className={'title-header'}>Bienvenido Escuela Bíblica Virtual</Title>
      </Col>  
      <Col span={12} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
        <Button className={'go-back-root-header'} onClick={goRoot}>
          Inicio
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button className={'go-back-button-header'} onClick={goBack}>
          Atras
        </Button>
      </Col>
    </Header>
    <Spinner loading={loading} >
      <Content>
        <Row>
          <Col span={24}>
            <Avatar size={150} src={book.image_url} />
            <Title className={'title-category'}>
              {book.title} - Capítulos
            </Title>
          </Col>
        </Row>
        <Row>
          {
            chapters.length > 0 ? <Col span={24}>
            <div className={'card'}>
              {
                chapters.map((c, i) => {
                  return <div key={i} className={'list'}>
                <span style={{color: c.color}} className={'chapter-number'}>{c.number}</span>
                <span onClick={() => goToQuestion(c.id)} style={{color: c.color}} className={'chapter-name'}>{c.title}</span>
                <span style={{float: 'right'}}>
                  <Tooltip title={c.detail} placement="topLeft">
                    <InfoCircleOutlined style={{fontSize: '20px'}} />
                  </Tooltip>
                </span>
                <Divider />
              </div>
                })
              }
            </div>
          </Col> : <Empty description={<span style={{color: 'white'}}>Sin Datos</span>} />
          }
        </Row>
      </Content>
    </Spinner>
  </Layout>
};

Chapters.propTypes = {
  bookId: PropTypes.number.isRequired,
  path_root: PropTypes.string,
};

export default Chapters;